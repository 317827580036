// src/axios.js
import axios from 'axios';

// Create an instance of axios
const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    withCredentials: true
});

// axios.interceptors.request.use(config => {
//     console.log('Request is going to be sent to the server');
//     const token = localStorage.getItem('token');
//     if (token) {
//         config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
// }, error => {
//     return Promise.reject(error);
// });

// You can add interceptors if needed
// axiosInstance.interceptors.request.use(
//   config => {
//     // Do something before request is sent
//     return config;
//   },
//   error => {
//     // Do something with request error
//     return Promise.reject(error);
//   }
// );

// axiosInstance.interceptors.response.use(
//   response => {
//     // Do something with response data
//     return response;
//   },
//   error => {
//     // Do something with response error
//     return Promise.reject(error);
//   }
// );

export default axiosInstance;

<template>
  <div id="app" class="bg-gray-100 min-h-screen">
      <router-view></router-view>
  </div>
</template>

<script>
import router from './router';

export default {
  name: 'App',
  router
};
</script>

<style>
a {
  color: #3490dc;
  text-decoration: none;
}
</style>
import travelAgencyIcon from '@/assets/icons/travel_agency.svg';

export const categoryMapping = {
    'Reisbureau': {
      name: 'Travel agency',
      logo: travelAgencyIcon
    },
    'Excursiebedrijf': {
      name: 'Excursion company',
      logo: travelAgencyIcon
    },
    'Touroperator': {
      name: 'Touroperator',
      logo: travelAgencyIcon
    },
    // Add more categories as needed
  };
  
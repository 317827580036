<template>
    <NavBar class="mb-4 border-b" />
    <div class="container mx-auto p-4 bg-gray-100" style="padding-top:5.5rem">
      <div class="flex flex-row space-x-4">
        <div class="basis-2/3">
          <OperatorDetails :id="operatorId" />
        </div>
        <div class="basis-1/3 pt-14">
          <BookSubscription />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import NavBar from '@/components/NavBar.vue';
  import OperatorDetails from '@/components/OperatorDetails.vue';
  import BookSubscription from '@/components/BookSubscription.vue';
  
  export default {
    components: {
      NavBar,
      OperatorDetails,
      BookSubscription
    },
    data() {
      return {
        operatorId: null
      };
    },
    created() {
        this.operatorId = this.$route.params.id;
    },
    methods: {}
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 1200px;
  }
  </style>
<template>
    <div class="flex justify-center items-center h-screen">
        <div class="w-1/3 bg-white rounded-lg shadow-lg p-8">
            <div class="flex justify-center items-center mb-8">
                <div class="mr-4">
                    <img src="../assets/logo.png" alt="Logo" class="h-12">
                </div>
            </div>
            <form @submit="register" class="space-y-4">
                <label class="input input-bordered flex items-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"
                        class="w-4 h-4 opacity-70">
                        <path
                            d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" />
                        <path
                            d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" />
                    </svg>
                    <input type="text" class="grow" placeholder="Enter your email" required v-model="email" />
                </label>
                <label class="input input-bordered flex items-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"
                        class="w-4 h-4 opacity-70">
                        <path fill-rule="evenodd"
                            d="M14 6a4 4 0 0 1-4.899 3.899l-1.955 1.955a.5.5 0 0 1-.353.146H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.293a.5.5 0 0 1 .146-.353l3.955-3.955A4 4 0 1 1 14 6Zm-4-2a.75.75 0 0 0 0 1.5.5.5 0 0 1 .5.5.75.75 0 0 0 1.5 0 2 2 0 0 0-2-2Z"
                            clip-rule="evenodd" />
                    </svg>
                    <input type="password" class="grow" value="password" placeholder="Enter your password" required v-model="password" />
                </label>
                <label class="input input-bordered flex items-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"
                        class="w-4 h-4 opacity-70">
                        <path fill-rule="evenodd"
                            d="M14 6a4 4 0 0 1-4.899 3.899l-1.955 1.955a.5.5 0 0 1-.353.146H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.293a.5.5 0 0 1 .146-.353l3.955-3.955A4 4 0 1 1 14 6Zm-4-2a.75.75 0 0 0 0 1.5.5.5 0 0 1 .5.5.75.75 0 0 0 1.5 0 2 2 0 0 0-2-2Z"
                            clip-rule="evenodd" />
                    </svg>
                    <input type="password" class="grow" value="password" placeholder="Reenter your password" required v-model="repassword" />
                </label>
                <button type="submit" class="w-full bg-indigo-500 text-white py-2 px-4 rounded-md">Register</button>
            </form>
            <p class="text-center mt-4">Already have an account? <a href="/">Login</a></p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            email: '',
            password: ''
        };
    },
    methods: {
        async register(event) {
            event.preventDefault();

            try {
                const response = await this.$axios.post('/users/register', {
                    email: this.email,
                    password: this.password
                });

                console.log('Registration successful:', response.data);
                // Handle successful registration
                // For example, redirect to the login page
                alert('Registration successful, you will be redirected to the login page. Use your email and password to login.');
                this.$router.push('/');
            } catch (error) {
                // Handle registration error
                // For example, display an error message
                console.error('Registration failed:', error);
            }
        }
    }
};
</script>
import { createRouter, createWebHistory } from 'vue-router';
import { isAuthenticated } from './utils/auth';
import OperatorsOverviewPage from './views/OperatorsOverviewPage.vue';
import OperatorDetailsPage from './views/OperatorDetailPage.vue';
import UserLoginPage from './views/UserLoginPage.vue';
import UserRegisterPage from './views/UserRegisterPage.vue';
import PaymentSuccessPage from './views/PaymentSuccessPage.vue';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'UserLogin',
      component: UserLoginPage
    },
    {
      path: '/register',
      name: 'UserRegister',
      component: UserRegisterPage
    },
    {
      path: '/dashboard',
      name: 'TouristOperators',
      component: OperatorsOverviewPage,
      meta: { requiresAuth: true }
    },
    {
      path: '/operator/:id',
      name: 'OperatorDetails',
      component: OperatorDetailsPage
    },
    {
      path: '/payment/success',
      name: 'PaymentSuccess',
      component: PaymentSuccessPage
    }
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated()) {
      next({ name: 'UserLogin' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;

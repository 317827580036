<template>
  <div v-if="operator" class="card bg-base-100 shadow-xl">
    <div class="card-body">
      <h2 class="card-title">
        <img :src="getCategoryInfo(operator.category).logo" alt="Category Logo" class="inline-block w-8 h-8 mr-2">
        {{ operator.name }}
        <UpgradeTooltip />
      </h2>
      <p class="text-gray-600">{{ operator.category }} from {{ operator.country }} {{ operator.description }}</p>
      <div class="divider"></div>
      <div>
        <div class="flex items-center pb-2">
          <i class="fa-solid fa-location-dot mr-2"></i>
          <h3 class="uppercase font-semibold text-gray-500">Contact Details</h3>
        </div>

        <p>
          <strong>Address: </strong>{{ operator.address }}
          <UpgradeTooltip />
        </p>



        <p><strong>Country: </strong> {{ operator.country }}</p>
      </div>
      <div class="divider"></div>
      <div>
        <div class="flex items-center pb-2">
          <i class="fa-solid fa-phone mr-2"></i>
          <h3 class="uppercase font-semibold text-gray-500">Communication ways</h3>
        </div>
        <p>
          <strong>Phone: </strong>{{ operator.telephone }}
          <UpgradeTooltip />
        </p>
        <p>
          <strong>Email: </strong>{{ operator.email }}
          <UpgradeTooltip />
        </p>
        <p>
          <strong>Website: </strong>{{ operator.website }}
          <UpgradeTooltip />
        </p>
      </div>
      <div class="divider"></div>
      <div>
        <div class="flex items-center pb-2">
          <i class="fa-solid fa-share-nodes mr-2"></i>
          <h3 class="uppercase font-semibold text-gray-500">Social media</h3>
        </div>
        <div v-if="!hasSubscription">
          Social media links are available after upgrading to a paid plan.
        </div>
        <div v-else class="flex flex-row gap-2">
          <!-- show only facebook if there is a value -->
          <div v-if="operatorContactDetails?.facebook">
            <p>
              <a :href="operatorContactDetails.facebook" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="32" height="32">
                  <path
                    d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z" />
                </svg>
              </a>
            </p>
          </div>
          <!-- else show deactivated facebook icon and when clicking on it show alert-->
          <div v-else>
            <p>
              <svg style="cursor: not-allowed;" @click="showAlert" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512" width="32" height="32">
                <path fill="gray"
                  d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256c0 128.7 94.1 235.2 216 254.8V330H151V256h65v-49.3c0-64.5 38.4-100.2 97.5-100.2c28.2 0 58.5 5 58.5 5v64h-32.9c-32.6 0-42.8 20.2-42.8 41v48h73l-11.9 74h-61.1V511.8C417.9 495.2 512 386.9 512 256z" />
              </svg>
            </p>
          </div>
          <!-- show only twitter if there is a value -->
          <div v-if="operatorContactDetails?.twitter">
            <p>
              <a :href="operatorContactDetails.twitter" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="32" height="32">
                  <path
                    d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                </svg>
              </a>
            </p>
          </div>
          <!-- else show deactivated twitter icon and when clicking on it show alert-->
          <div v-else>
            <p>
              <svg style="cursor: not-allowed;" @click="showAlert" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512" width="32" height="32">
                <path fill="gray"
                  d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
              </svg>
            </p>
          </div>
          <!-- show only instagram if there is a value -->
          <div v-if="operatorContactDetails?.instagram">
            <p>
              <a :href="operatorContactDetails.instagram" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="32" height="32">
                  <path
                    d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                </svg>
              </a>
            </p>
          </div>
          <!-- else show deactivated instagram icon and when clicking on it show alert-->
          <div v-else>
            <p>
              <svg style="cursor: not-allowed;" @click="showAlert" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512" width="32" height="32">
                <path fill="gray"
                  d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
              </svg>
            </p>
          </div>
          <!-- show only youtube if there is a value -->
          <div v-if="operatorContactDetails?.youtube">
            <p>
              <a :href="operatorContactDetails.youtube" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="32" height="32">
                  <path
                    d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z" />
                </svg>
              </a>
            </p>
          </div>
          <!-- else show deactivated youtube icon and when clicking on it show alert-->
          <div v-else>
            <p>
              <svg style="cursor: not-allowed;" @click="showAlert" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512" width="40" height="32">
                <path fill="gray"
                  d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z" />
              </svg>
            </p>
          </div>
          <!-- show only linkedin if there is a value -->
          <div v-if="operatorContactDetails?.linkedin">
            <p>
              <a :href="operatorContactDetails.linkedin" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="32" height="32">
                  <path
                    d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
                </svg>
              </a>
            </p>
          </div>
          <!-- else show deactivated linkedin icon and when clicking on it show alert-->
          <div v-else>
            <p>
              <svg style="cursor: not-allowed;" @click="showAlert" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512" width="32" height="32">
                <path fill="gray"
                  d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
              </svg>
            </p>
          </div>
          <!-- show only tiktok if there is a value -->
          <div v-if="operatorContactDetails?.tiktok">
            <p>
              <a :href="operatorContactDetails.tiktok" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="32" height="32">
                  <path
                    d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" />
                </svg>
              </a>
            </p>
          </div>
          <!-- else show deactivated tiktok icon and when clicking on it show alert-->
          <div v-else>
            <p>
              <svg style="cursor: not-allowed;" @click="showAlert" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512" width="32" height="32">
                <path fill="gray"
                  d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" />
              </svg>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="card-actions justify-end">
      <button class="btn btn-primary m-8" @click="goBack">Back</button>
    </div>
  </div>
  <div v-else>
    <p>Loading...</p>
  </div>
</template>

<script>
import { categoryMapping } from '@/utils/categoryMapping.js';
import earthAsia from '@/assets/icons/earth-asia.svg';
import UpgradeTooltip from '@/components/UpgradeTooltip.vue';
import { useUserStore } from '@/stores/userStore';

export default {
  components: {
    UpgradeTooltip
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      operator: null,
      operatorContactDetails: null,
      // TODO add code for looking if paying user
      hasSubscription: false
    };
  },
  mounted() {
    //get the subscription status from the useUserStore
    const userStore = useUserStore();
    this.hasSubscription = userStore.getSubscriptionStatus;
    //access the getter from the userStore
  },
  watch: {
    id: {
      immediate: true,
      handler(newId) {
        this.fetchTouristOperator(newId);
        this.fetchTouristOperatorContactDetails(newId);
      }
    }
  },
  methods: {
    async fetchTouristOperator(id) {
      try {
        const response = await this.$axios.get(`/touristOperators/${id}`);
        console.log('Operator:', response.data[0]);
        this.operator = response.data[0]; // API returns an array and we want the first element
      } catch (error) {
        console.error('Error fetching tourist operator:', error);
      }
    },
    async fetchTouristOperatorContactDetails(id) {
      try {
        const response = await this.$axios.get(`/contactDetails/${id}`);
        this.operatorContactDetails = response.data[0]; // API returns an array and we want the first element
        console.log('Contact details:', this.operatorContactDetails);
      } catch (error) {
        console.error('Error fetching tourist operator contact details:', error);
      }
    },
    getCategoryInfo(category) {
      return categoryMapping[category] || { name: category, logo: earthAsia };
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      alert('Sorry, we do not have a link for this operator at the moment');
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 800px;
}</style>
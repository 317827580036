<template>
  <NavBar class="mb-4 border-b" />
  <h2>Subscription Status: {{ this.hasSubscription ? 'Active' : 'Inactive' }}</h2>
  <div class="container mx-auto p-4 pt-16 bg-gray-100">
    <div class="flex mb-4">
      <div>
        <label class="form-control w-full max-w-xs">
          <div class="label">
            <span class="label-text">Choose the operators country</span>
          </div>
          <select class="select select-bordered" v-model="selectedCountry"
            @change="fetchTouristOperators({ ...(selectedCountry !== 'All' ? { country: selectedCountry } : {}), ...(selectedCategory !== 'All' ? { category: selectedCategory } : {}) })">
            <option selected>All</option>
            <option value="Switzerland">Switzerland</option>
            <option>UK</option>
          </select>
        </label>
      </div>
      <div class="pl-4">
        <label class="form-control w-full max-w-xs">
          <div class="label">
            <span class="label-text">Choose the operators category</span>
          </div>
          <select class="select select-bordered" v-model="selectedCategory"
            @change="fetchTouristOperators({ ...(selectedCountry !== 'All' ? { country: selectedCountry } : {}), ...(selectedCategory !== 'All' ? { category: selectedCategory } : {}) })">
            <option selected>All</option>
            <option v-for="category in touristOperatorCategories" :key="category.id" :value="category.category">
              {{ category.category }}
            </option>
          </select>
        </label>
      </div>
    </div>
    <!-- Section containing the list of tourist operators -->
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      <div v-for="operator in touristOperators" :key="operator.id"
        class="card bg-base-100 shadow-xl transition-transform transform hover:scale-105 hover:shadow-2xl">
        <router-link :to="{ name: 'OperatorDetails', params: { id: operator.id } }" class="card-body">
          <div class="flex justify-between">
            <div class="flex items-center max-w-full flex-row flex-shrink-0 overflow-hidden whitespace-nowrap">
              <img :src="getCategoryInfo(operator.category).logo" alt="Category Logo" class="inline-block w-8 h-8 mr-2 pr-1">
              <h2 class="card-title mr-2 overflow-hidden text-ellipsis">
                {{ operator.name }}
              </h2>
              <UpgradeTooltip />
            </div>
            <!--TODO
            <div class="flex flex-row"><i class="fa-regular fa-heart"></i></div>
            -->
          </div>
          <p>{{ getCategoryInfo(operator.category).name }}</p>
          <!--TODO
          <p class="text-gray-700">{{ operator.description }}</p>
          -->
          <p><i class="fa-solid fa-location-dot"></i> {{ operator.country }}</p>
          <OperatorReviewStats :operator="operator" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import UpgradeTooltip from '@/components/UpgradeTooltip.vue';
import OperatorReviewStats from '@/components/OperatorReviewStats.vue';
import { categoryMapping } from '@/utils/categoryMapping.js';
import earthAsia from '@/assets/icons/earth-asia.svg';
import { useUserStore } from '@/stores/userStore';

export default {
  components: {
    NavBar,
    UpgradeTooltip,
    OperatorReviewStats
},
  data() {
    return {
      selectedCountry: 'All',
      selectedCategory: 'All',
      touristOperators: [],
      touristOperatorCategories: [],
      hasSubscription: false
    };
  },
  mounted() {
    //get the subscription status from the useUserStore
    const userStore = useUserStore();
    this.hasSubscription = userStore.getSubscriptionStatus;
    //access the getter from the userStore
    this.fetchTouristOperators();

  },
  methods: {
    async fetchTouristOperatorsCategories() {
      try {
        const response = await this.$axios.get('/touristOperators/categories');
        this.touristOperatorCategories = response.data;
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    },
    async fetchTouristOperators(filter = {}) {
      try {
        let url = '/touristOperators';
        const params = new URLSearchParams(filter);
        if (params.toString()) {
          url += `?${params.toString()}`;
        }
        const response = await this.$axios.get(url);
        this.touristOperators = response.data;
      } catch (error) {
        console.error('Error fetching tourist operators:', error);
      }
    },
    getCategoryInfo(category) {
      return categoryMapping[category] || { name: category, logo: earthAsia };
    }
  },
  created() {
    this.fetchTouristOperatorsCategories();
  }
};
</script>

<style scoped>
.container {
  max-width: 1200px;
}

.stat-title,
.stat-value {
  font-size: 0.85rem;
  /* Adjust the font size as needed */
}
</style>
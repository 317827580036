<template>
    <div v-if="!hasSubscription" class="tooltip tooltip-bottom"
        data-tip="Not available on the free plan. Click here to upgrade.">
        <a href="#" class="upgrade-link">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                    d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
            </svg>
        </a>
    </div>
</template>

<script>

import { useUserStore } from '@/stores/userStore';

export default {
    data() {
        return {
            hasSubscription: false
        };
    },
    name: 'UpgradeTooltip',
    components: {},
    props: {},
    methods: {},
    mounted() {
        //get the subscription status from the useUserStore
        const userStore = useUserStore();
        this.hasSubscription = userStore.getSubscriptionStatus;
        console.log('Subscription status from upgrade link:', this.hasSubscription);
    }
}
</script>
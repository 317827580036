import { defineStore } from 'pinia';
import axios from '@/axios'; // Import the global axios instance

export const useUserStore = defineStore('user', {
    //initial state
    state: () => ({
        hasSubscription: JSON.parse(localStorage.getItem('hasSubscription')) || false,
        isLoggedIn: JSON.parse(localStorage.getItem('isLoggedIn')) || false,
    }),
    actions: {
        async fetchSubscriptionStatus() {
            try {
                console.log('Fetching subscription status');
                const response = await axios.get(`/users/subscription`, { withCredentials: true });
                console.log(response.data);
                this.setSubscriptionStatus(response.data.hasSubscription);
                console.log(this.getSubscriptionStatus);
            } catch (error) {
                console.error('Failed to fetch subscription status', error);
            }
        },
        setSubscriptionStatus(status) {
            this.hasSubscription = status;
            console.log("writing to local storage the hasSubscription status");
            localStorage.setItem('hasSubscription', JSON.stringify(status));
        },
        setLoggedIn(status) {
            this.isLoggedIn = status;
            localStorage.setItem('isLoggedIn', JSON.stringify(status));
        }
    },
    getters: {
        getSubscriptionStatus() {
            return this.hasSubscription;
        },
        getLoggedIn() {
            return this.isLoggedIn;
        }
    },
});

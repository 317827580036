<template>
    <div class="navbar bg-base-100 fixed top-0 z-10">
        <div class="flex-1">
            <a href="/dashboard" class="btn btn-ghost text-xl">
                <img src="../assets/logo.png" alt="Logo" class="h-8">
            </a>
        </div>
        <div class="flex-none">
            <div class="dropdown dropdown-end">
                <div tabindex="0" role="button" class="btn btn-ghost btn-circle avatar">
                    <div class="w-10 rounded-full">
                        <img alt="Profile icon of the user logged in"
                            src="https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg" />>
                    </div>
                </div>
                <ul tabindex="0" class="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
                    <li><a href="/" @click="logout">Logout</a></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'NavBar',
    components: {},
    props: {},
    methods: {
        logout() {
            localStorage.removeItem('token');
        }
    }
}
</script>
<template>
    <div class="flex justify-center items-center h-screen">
        <div class="w-1/3 bg-white rounded-lg shadow-lg p-8">
            <div class="flex justify-center items-center mb-8">
                <div class="mr-4">
                    <img src="../assets/logo.png" alt="Logo" class="h-12">
                </div>

            </div>
            <form @submit="login" class="space-y-4">
                <label class="input input-bordered flex items-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"
                        class="w-4 h-4 opacity-70">
                        <path
                            d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" />
                        <path
                            d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" />
                    </svg>
                    <input type="text" class="grow" placeholder="Enter your email" required v-model="email" />
                </label>
                <label class="input input-bordered flex items-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"
                        class="w-4 h-4 opacity-70">
                        <path fill-rule="evenodd"
                            d="M14 6a4 4 0 0 1-4.899 3.899l-1.955 1.955a.5.5 0 0 1-.353.146H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.293a.5.5 0 0 1 .146-.353l3.955-3.955A4 4 0 1 1 14 6Zm-4-2a.75.75 0 0 0 0 1.5.5.5 0 0 1 .5.5.75.75 0 0 0 1.5 0 2 2 0 0 0-2-2Z"
                            clip-rule="evenodd" />
                    </svg>
                    <input type="password" class="grow" placeholder="Enter your password" value="password" required
                        v-model="password" />
                </label>
                <button type="submit" class="w-full bg-indigo-500 text-white py-2 px-4 rounded-md">Login</button>
            </form>
            <p class="text-center mt-4">Don't have an account? <a href="/register">Create account</a></p>
            <p class="text-center mt-4 italic">Use <strong>any email and password</strong> combination for the moment</p>
        </div>
    </div>
</template>

<script>
import { useUserStore } from '../stores/userStore';

export default {
    data() {
        return {
            email: '',
            password: ''
        };
    },
    computed: {
        userStore() {
            return useUserStore();
        }
    },
    mounted() {
        if (this.userStore.isLoggedIn) {
            this.$router.push('/dashboard');
        }
    },
    methods: {
        login(event) {
            event.preventDefault();
            console.log('Logging in with:', this.email, this.password);
            // Perform login logic here
            this.$axios.post('/users/login', { email: this.email, password: this.password })
                .then(response => {
                    const token = response.data.token;
                    const userId = response.data.userId;
                    // Save the JWT token in the local storage
                    localStorage.setItem('token', token);
                    localStorage.setItem('userId', userId);
                    localStorage.setItem('email', this.email);

                    // Set the user as logged in
                    this.userStore.setLoggedIn(true);
                    this.userStore.fetchSubscriptionStatus().then(() => {
                        // Redirect to the dashboard
                        this.$router.push('/dashboard');
                    });
                })
                .catch(error => {
                    // Handle login error
                    if (error.response.status === 401 || error.response.status === 400) {
                        // Show invalid login message
                        this.email = '';
                        this.password = '';
                        alert('The email / password combination you provided do not exist. Please try again.');
                    } else {
                        console.error('Login failed:', error);
                    }
                });
        }
    }
};
</script>
<template>
    <div class="p-10 flex flex-col items-center justify-center h-screen">
        <div class="bg-green-500 text-white p-6 rounded-lg shadow-lg max-w-md w-full text-center">
            <svg class="w-16 h-16 mx-auto mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
            </svg>
            <h2 class="text-2xl font-bold mb-2">Payment Successful!</h2>
            <p class="text-lg mb-4">User has been upgraded to premium.</p>
            <p class="text-base">Redirecting to login page... Please login for the changes to take effect.</p>
        </div>
    </div>
</template>
  
<script>

export default {
    mounted() {
        // Reload the page after 5 seconds
        //clear local storage
        localStorage.clear();
        setTimeout(() => {
            window.location.href = '/';
        }, 5000); // Adjust the delay as needed
    },
};
</script>
  
<style scoped>
/* Custom styles can be added here */
</style>
  
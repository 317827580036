<template>
    <div class="flex items-center justify-center">
        <div class="bg-white p-4 rounded-lg text-center">
            <h3 class="pb-4 text-lg font-semibold text-left">Upgrade your account and get access to all the data</h3>
            <p class="pb-4 text-left">Contact directly the provider and discuss about your next opportunities</p>
            <div class="flex justify-center">
                <button class="btn btn-primary m-8" @click="redirectToCheckout">Upgrade to Premium</button>
            </div>
        </div>
    </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';

export default {
    methods: {
        async redirectToCheckout() {
            const stripe = await loadStripe('pk_test_51PW3dm09PqnRcxuxJmOkqnTddfLVTAXZXQjtsCO3XzMLOvsIQTOAI2LatOGeXzd0ct2klHnO1slWdox89ksWZlmq00g6fR2swq');//TODO change this to the actual publishable key
            const userId = localStorage.getItem('userId');
            const userEmail = localStorage.getItem('email');
            const token = localStorage.getItem('token');

            try {
                const response = await this.$axios.post('payments/create-checkout-session',
                    {
                        userId,
                        userEmail
                    }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                });
                const session = response.data;
                const result = await stripe.redirectToCheckout({ sessionId: session.id });
                if (result.error) {
                    console.error(result.error.message);
                }
            } catch (error) {
                console.error('Error creating checkout session:', error);
            }
        },
    },
};
</script>
// Code to create a Vue application instance and mount it to the DOM
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import './assets/css/tailwind.css'
import axios from '@/axios';


// Create a Pinia instance
const pinia = createPinia();

// Create a Vue application instance
const app = createApp(App);
// Install Pinia into the Vue application
app.use(pinia);
app.use(router);


app.config.globalProperties.$axios = axios;

// Mount the application to the DOM
app.mount('#app');
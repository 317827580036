<template>
    <div class="flex flex-row items-center justify-between mt-1">
        <div class="flex flex-col">
            <span class="stat-title mb-1 uppercase" id="">Google rating</span>
            <span class="stat-value" id="">{{ operator.rating }} ⭐️</span>
        </div>
        <div class="flex flex-col">
            <span class="stat-title mb-1 uppercase" id="">Reviews</span>
            <span class="stat-value" id="">{{ operator.no_of_ratings }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OperatorReviewStats',
    components: {},
    props: {
        operator: {
            type: Object,
            required: true
        }
    },
    methods: {}
}
</script>

<style scoped>
.stat-title,
.stat-value {
    font-size: 0.85rem;
    /* Adjust the font size as needed */
}
.stat-value 
{
    line-height: 0.5rem;
}
</style>